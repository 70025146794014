// react
import React, { Component, createRef } from "react";

// vendors

// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// css
import styles from "./VideoModal.module.scss";
import { Button, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------
/* eslint-disable */
interface VideoModalProps { }

interface VideoModalState {
    isOpen: boolean;
    onSave?: ( file: File ) => void;
    uri: any;
    onClose?: () => void;
}

const defaultState: VideoModalState = {
    isOpen: false,
    uri: [],
};

class VideoModal extends Component<VideoModalProps, VideoModalState> {
    CanvasRef = createRef<any>();
    VideoRef = createRef<any>();

    state = defaultState;
 
    open = (
        uri: any,
        onSave: VideoModalState["onSave"],
        onClose: VideoModalState["onClose"]
    ) =>
        this.setState( () => ( {
            isOpen: true,
            onSave,
            uri,
            onClose,
        } ) );

    /**
     * @param noEdit boolean to indicated whether the popup was closed withou file upload
     */
    close = ( noEdit: boolean = false ) =>
        this.setState(
            () => defaultState,
            //@ts-ignore
            this.state.onClose?.bind( null, noEdit )
        );

    loadedmetadataListner = () => {
        if ( this.CanvasRef.current && this.VideoRef.current ) {
            this.CanvasRef.current.width = this.VideoRef.current.videoWidth;
            this.CanvasRef.current.height = this.VideoRef.current.videoHeight;
        }
    };

    getThumbnail = () => {
        const videoEl = this.VideoRef.current;
        const canvalEl = this.CanvasRef.current;

        const canvasCtx = canvalEl?.getContext( "2d" );

        canvasCtx.drawImage(
            videoEl,
            0,
            0,
            videoEl.videoWidth,
            videoEl.videoHeight
        );

        // canvalEl.toDataURL();
        canvalEl.toBlob(
            ( blob: Blob ) => {
                const thumbnail = new File( [blob], "thumbnail", {
                    type: "image/jpeg",
                } );

                // save file
                this.state.onSave?.( thumbnail );
            },
            "image/jpeg",
            1
        );
    };

    render () {
        const { isOpen, uri, onSave } = this.state;
        return (
            <Modal
            open={isOpen}
            onClose={ this.close.bind( null, true ) }
            disableEnforceFocus
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
              
                <Typography id="modal-modal-description" className={ styles.content }>
                
             
                    <span className={ styles.actions }>
                        <CloseIcon
                            style={{cursor: "pointer",color:"white"}}
                            onClick={ this.close.bind( null, true ) }
                        />
                    </span>

                    {/* <span className="fs-11 bp-7 f-bold">Preview</span> */}
                  
                    <canvas
                        ref={ this.CanvasRef }
                        className={ styles.hidden }
                    ></canvas>

                    <video
                        id="my-video"
                        ref={ this.VideoRef }
                        controls
                        src={typeof uri === "string"?uri :  uri[0] }
                        muted = {uri[1]}
                        onPlay={uri[1]}
                        autoPlay
                        //@ts-ignore
                        onLoadedMetadata={ this.loadedmetadataListner }
                    >
                        <source type="video/mp4"></source>
                    </video>

                    {/* <div className={styles.seeker}>
                        Seek to <select id="set-video-seconds"></select> seconds{" "}
                    </div> */}

                    {/* { onSave && (
                        <Button
                            onClick={ this.getThumbnail }
                            className={ styles.button }
                        >
                            Select current frame as thumbnail & upload
                        </Button>
                    ) } */}
                </Typography>
            </Modal>
        );
    }
}

// ----------------------------------------------------------------------------------

export default VideoModal;
