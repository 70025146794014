//s#Url for ------------ app,tvs,qa,daimler,hmie
// export const s3Url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";

// export const s3Url = "https://tvs-xcpep.s3.ap-south-1.amazonaws.com/";

// export const s3Url = "https://prod-daimler.s3.ap-south-1.amazonaws.com/";
// export const s3Url = "https://hmie-xcpep.s3.ap-south-1.amazonaws.com/"
// const currentUrl = window.location.href;
// const getS3url = () => {
//   let S3url = "";
  // if(!isUndefined(currentUrl)){

  //     if(currentUrl.includes("qa")){
  //       S3url= "https://qa-xcpep.s3.ap-south-1.amazonaws.com/";
  //     }else if(currentUrl.includes("app")){
  //       S3url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";
  //     }
  // }
//   if (typeof currentUrl !== "undefined") {
//     if (currentUrl.includes("tvsm.xcpep.com")) {
//       S3url = "https://tvs-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("daimler.xcpep.com")) {
//       S3url = "https://prod-daimler.s3.ap-south-1.amazonaws.com/";
//     } else if (currentUrl.includes("app.xcpep.com")) {
//       S3url = "https://s3.ap-south-1.amazonaws.com/prod.xcpep.com/";
//     } else if (currentUrl.includes("hmie.xcpep.com")) {
//       S3url = "https://hmie-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("mnm.xcpep.com")) {
//       S3url = "https://mnm-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("bal.xcpep.com")) {
//       S3url = "https://bal-xcpep.s3.ap-south-1.amazonaws.com/";
//     } else if (currentUrl.includes("ae.xcpep.com")) {
//       S3url = "https://ae-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("olaelectric.xcpep.com")) {
//       S3url = "https://ola-xcpep.s3.ap-south-1.amazonaws.com/";
//     } else if (currentUrl.includes("mlmm.xcpep.com")) {
//       S3url = "https://meml-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("subscription.xcpep.com")) {
//       S3url = "https://subscription-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("mck.xcpep.com/")) {
//       S3url = "https://mck-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("av.xcpep.com/")) {
//       S3url = "https://av-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("cnhi.xcpep.com/")) {
//       S3url = "https://cnhi-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("tm.xcpep.com/")) {
//       S3url = "https://tm-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("ucal.xcpep.com/")) {
//       S3url = "https://ucal-xcpep.s3-accelerate.amazonaws.com/";
//     } else if (currentUrl.includes("mgi.xcpep.com/")) {
//       S3url = "https://mgi-xcpep.s3-accelerate.amazonaws.com/";
//     }
//   }
//   return S3url;
// };

// export const s3Url = getS3url();
export const s3Url = "https://qa2-xcpep.s3.ap-south-1.amazonaws.com/";